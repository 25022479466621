<template>
    <h1 class="page-header">
        {{ $t( "pages.equipment.pageHeadline" ) }}
    </h1>

    <h3 class="page-sub-header">
        <n-icon class="page-header-icon">
            <lend-icon />
        </n-icon>
        {{ $t( "pages.equipment.pageSubHeadline1" ) }}
    </h3>
    <p class="body-text" v-html="$t( 'pages.equipment.paragraph1' )"></p>

    <h3 class="page-sub-header">
        <n-icon class="page-header-icon">
            <shop-icon />
        </n-icon>
        {{ $t( "pages.equipment.pageSubHeadline2" ) }}
    </h3>
    <p class="body-text" v-html="$t( 'pages.equipment.paragraph2' )"></p>
</template>

<script>
import { ShoppingCart as ShopIcon } from '@vicons/carbon'
import { ScubaMask as LendIcon } from '@vicons/tabler'

export default {
    components: { ShopIcon, LendIcon }
}
</script>

<style scoped>

</style>